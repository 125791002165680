<template>
  <div>
    <CToaster :autohide="3000">
          <template v-for="info in infoList">
            <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
              {{ info.message }}.
            </CToast>
          </template>
        </CToaster>
    <CCard class="c-card">
      <CCardBody>
        <CCardTitle class="c-card-title">Pricing Plans</CCardTitle>

        <div class="initial">
          <CRow>
            <CCol :sm="6" v-for="(plan, index) in sortedPlans" :key="index">
              <CCard>
                <CCardBody class="card-plan">
                  <CCardTitle class="card-plan-title">{{ plan.title }}</CCardTitle>
                    <CCardTitle class="card-plan-billing-period">Per {{ plan.gracePeriod >= 365 ? 'Year' : plan.gracePeriod > 0 && plan.gracePeriod <= 1 ? 'Usage' : 'Week' }}</CCardTitle>
                    <CCardText class="card-plan-price">${{ plan.price }}</CCardText>
                    <CCardText class="card-plan-description">Subscription by {{ plan.gracePeriod }} Days</CCardText>
                    <CCardText class="card-plan-description">{{ plan.edit }}</CCardText>
                    <CCardText class="card-plan-description">Account will be auto deactivated by the system once the grace period ends.</CCardText>
                    <CCardText class="card-plan-description">A grace period of {{ plan.gracePeriod }} Days is given for the user to renew their subscription after the previously subscribed plan expired.</CCardText>
                    <button class="card-plan-btn" :key="plan._id" :class="{ 'blue-gradient': plan._id !== currentPlan }"
                    @click="upgradePlan(plan._id)"
                      :disabled="plan._id === currentPlan">
                      {{ plan._id === currentPlan ? 'Your Current Plan' :
                         currentPlan === '' ? 'Subscribe Plan' :
                          'Upgrade Plan'  }}
                    </button>
                    <br>
                    <button class="cancel-btn" :key="plan.id"
                    @click="cancelPlan()"
                    v-if="plan._id === currentPlan && currentPlan !== ''">
                      Cancel Subscription
                    </button>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
    <div>
        <CModal title="Upgrade Subscription" :show.sync="upgradeSubsPopup">
            <div class="centered">
              <h4>Are you sure on upgrading plan ?</h4>
              <p>(This change will be update immediately)</p>
            </div>
            <div slot="footer" class="w-100">
            <CButton
              class="ml-1 mr-1 float-right"
              color="primary"
              @click="upgradeUserPlan()"
            >
              Subscribe
            </CButton>
            <CButton
              class="ml-1 mr-1 float-right"
              color="secondary"
              @click="upgradeSubsPopup = false"
            >
              Cancel
            </CButton>
          </div>
        </CModal>
    </div>
    <div>
        <CModal title="Cancel Subscription" :show.sync="cancelSubsPopup" >
            <div class="centered">
                <h4>Are you sure on canceling your current plan ?</h4>
                <p>(This change will be update immediately)</p>
            </div>
            <div slot="footer" class="w-100">
            <CButton
              class="ml-1 mr-1 float-right"
              color="danger"
              @click="cancelUserPlan"
            >
              Cancel Subscription
            </CButton>
            <CButton
              class="ml-1 mr-1 float-right"
              color="secondary"
              @click="cancelSubsPopup = false"
            >
              Cancel
            </CButton>
          </div>
        </CModal>
    </div>
    <div>
        <CModal title="Payment" :show.sync="paymentPopup" size="lg">
          <div id="link-authentication-element"></div>
            <div id="payment-element"></div>
            <div slot="footer" class="w-100">
            <CButton
              class="ml-1 mr-1 float-right"
              color="primary"
              @click="handleSubmit"
            >
              Continue
            </CButton>
            <CButton
              class="ml-1 mr-1 float-right"
              color="secondary"
              @click="paymentPopup = false"
            >
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>
  </div>
</template>

<script>
// import router from '../../router';
import { loadStripe } from '@stripe/stripe-js';
let userData = window.localStorage.getItem('user');
let userDataJson = JSON.parse(userData)
let apiUrl = process.env.VUE_APP_API_URL;
// let apiUrl = 'http://localhost:3000/v1/';
let stripeKey = process.env.VUE_APP_STRIPE_KEY;

export default {
  name: 'PricingPlan',
  data: function () {
    return {
      infoList: [],
      paymentPopup: false,
      cancelSubsPopup: false,
      upgradeSubsPopup: false,
      stripeKey,
      stripe: null,
      elements: null,
      "plans": [],
      purchasePlansIsShown: true,
      message: "",
      emailAddress: "",
      isLoading: false,
      "userSubs": [],
      currentPlan: null,
      userSubsId: null,
      planId: null,
      tempUserId: '64a682c62e3c212acebe0de0',
      tempPlanId: '64a69d338fcae05024c59b0e'
    }
  },
  computed: {
    sortedPlans() {
      // Use slice() to create a copy of the array before sorting
      return this.plans.slice().sort((a, b) => a.price - b.price);
    }
  },
  methods: {

    showPricePlan() {
      console.log(this.planId)
      this.initialize()
    },

    fetchUserSubData() {
      let url = apiUrl+'usersubs';
      let status = 'active';
      let userId = userDataJson.id;

      return api.call('get', url+'?status='+status+'&userId='+userId)
        .then((response) => {
          console.log('hey', response);
          if (response.data.results){
            if(response.data.totalResults > 0) {
              this.currentPlan = response.data.results[0].pricePlanId
              this.userSubsId = response.data.results[0].id
            }else{
              this.currentPlan = ''
            }
          }
          return response.data
        });
    },

    fetchPlanData() {
      let url = apiUrl+'/plans';
      return api.call('get', url)
        .then((response) => {
          return response.data
        });
    },

    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },

    fetchData() {
      var url = apiUrl+'payments/create-payment-intent';
      let data = {
            userId: userDataJson.id,
            pricePlanId: this.planId,
      }
      return api.call('post', url, data)
        .then((response) => {
          return response.data
        });
    },

    register() {
      this.fetchData().then((res) => {
        this.paymentPopup = true
      }).catch(({ data }) => {
        this.toast(
          "Error",
          data.message,
          "danger"
        );
        console.log(data.message)
      })
    },

    checkUserPlan() {
      this.fetchUserSubData().then(userSubs => {
        this.userSubs = userSubs.some(
          userSub => userSub.userId === userDataJson.id && userSub.status === "active"
        );
        this.currentPlan = this.userSubs
        console.log(this.currentPlan)
        if(this.userSubs){
          console.log("User have a current plan.");
        }else{
          console.log("User does not have a current plan.");
        }
      })
      this.fetchUserSubData().then((res) => {
        this.userSubs = res.results.filter(usersub => usersub.userId === userDataJson.id);
      }).catch(({ data }) => {
        this.toast(
          "Error",
          data.message,
          "danger"
        );
      })
    },

    unsubscribeUserPlan(){
      let url = apiUrl+'usersubs/unsubscribe/'+this.userSubsId;
      let payload = {
        userId: userDataJson.id
      }
      return api.call('patch', url, payload)
        .then((response) => {
          console.log(response)
          this.toast(
            "Success",
            response.data.message,
            "success"
          );
          // this.$router.go();
          setTimeout(function () {
            window.location.reload();
          }, 1500);
        })
    },

    cancelUserPlan() {
      this.cancelSubsPopup = false;
      this.unsubscribeUserPlan()
    },

    cancelPlan() {
      this.cancelSubsPopup = true;
    },

    upgradeUserPlan() {
      this.upgradeSubsPopup = false;
      this.showPricePlan(this.planId)
    },

    upgradePlan(planId) {
      this.upgradeSubsPopup = true;
      this.planId = planId;
    },

    async initialize() {
      this.fetchPlanData().then((res) => {
      }).catch(({ data }) => {
        this.toast(
          "Error",
          data.message,
          "danger"
        );
        console.log(data.message)
      })
      let res;
      try {
        res = await this.fetchData(this.planId);
        this.paymentPopup = true
        console.log('res 2nd',res);
      } catch (res) {
        console.log(res);
        this.toast(
          "Error",
          res.data.message,
          "danger"
        );
      }
      const { clientSecret } = await res;
      console.log(clientSecret)
      const appearance = {
        theme: "stripe",
      };
      try {
        let pkStripe = this.stripeKey;
        this.stripe = await loadStripe(pkStripe);
        console.log("Stripe", this.stripe);
        this.elements = this.stripe.elements({ appearance, clientSecret });
          // Now you can use this.elements for further interactions with Stripe Elements
        } catch (error) {
        console.error("Error initializing Stripe:", error);
      }
      console.log("Elements :", this.elements)

      const linkAuthenticationElement = this.elements.create("linkAuthentication");
      console.log(linkAuthenticationElement)
      linkAuthenticationElement.mount("#link-authentication-element");


      linkAuthenticationElement.on("change", (event) => {
        this.emailAddress = event.value.email;
      });


      const paymentElementOptions = {
        layout: "tabs",
      };


      const paymentElement = this.elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");

    },

    async handleSubmit() {
      this.isLoading = true;
      
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `https://fsrapp.firesafetyreview.com/#/pricing-plan`,
          receipt_email: this.emailAddress,
        },
        redirect: "if_required",
      });

      if (error) {
        return;
      } 
        
      this.paymentPopup = false;
      this.isLoading = false;
      this.toast(
        "Success",
        "Payment sucess!",
        "success"
      );
      setTimeout(() => {
        this.$router.go();
      }, 1000);
    },

    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.toast(
            "Success",
            "Payment succeeded!",
            "success"
          );
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.toast(
            "Info",
            "Your payment is processing.",
            "info"
          );
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.toast(
            "Error",
            "Your payment was not successful, please try again.",
            "danger"
          );
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.toast(
            "Error",
            "Something went wrong.",
            "danger"
          );
          this.showMessage("Something went wrong.");
          break;
      }
    },
    showMessage(messageText) {
      this.message = messageText;
      setTimeout(() => {
        this.message = "";
      }, 4000);
    },
  },
  mounted() {
    console.log(userDataJson)
    this.fetchUserSubData();
    this.fetchPlanData().then((res) => {
      this.plans = res.pricePlan.filter(plan => plan.isActive === true);
    }).catch(({ data }) => {
      this.toast(
        "Error",
        data.message,
        "danger"
      );
      console.log(data.message)
    })
    console.log(this.currentPlan)
  }
}
</script>

<style>
.c-card {
  max-width: 59rem;
  width: 100%;
  margin: 0 auto;
}

.card-plan {
  padding: 32px;
}

.c-card-title {
  margin-bottom: 40px;
}

.card-plan-title {
  color: #434343;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.card-plan-billing-period {
  color: #374754;
  text-align: center;
  font-size: 16px;
  line-height: 23px;
}

.card-plan-price {
  color: #434343;
  font-size: 48px;
  font-weight: 700;
  line-height: 84px;
  letter-spacing: 0.2px;
  text-align: center;
}

.card-plan-description {
  color: #434343;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.card-plan-btn {
  padding: 10px;
  border-radius: 32px;
  background: #fff;
  border: 1px solid #0A1439;
  width: 100%;
  max-width: 247px;
  margin: 0 auto;
  display: block;
  font-size: 15px;
  color: #0A1439;
}
.cancel-btn {
  padding: 10px;
  background: #FF4D4F;;
  border: none;
  border-radius: 32px;
  outline: none;
  width: 100%;
  max-width: 247px;
  margin: 0 auto;
  display: block;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
}

.cancel-btn:hover {
  cursor: pointer;
}


.card-plan-btn.blue-gradient {
  background-image: linear-gradient(90deg, #252AE9 0%, rgba(14, 124, 136, 0.50) 100%);
  color: #fff;
  font-weight: 700;
}

.card-plan-btn.blue-gradient:hover {
  cursor: pointer;
}

.card-plan-btn[disabled] {
  cursor: not-allowed;
}

.centered {
    text-align: center; /* Center horizontally */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
